// @refresh reload
import { Router } from "@solidjs/router";
import { FileRoutes } from "@solidjs/start";
import { Suspense, onMount } from "solid-js";
import { MetaProvider } from "@solidjs/meta";
import "@park-ui/tailwind-plugin/preset.css";
import "@fontsource/inter";
import "@fontsource/inter/500.css";
import { createBrowserClient } from "@supabase/ssr";
import { MixpanelProvider } from "./context/MixpanelProvider";

import { AuthProvider } from "./context/AuthProvider";
import JulepProvider from "./context/JulepProvider";
import "./app.css";
import PlaygroundProvider from "./context/PlaygroundProvider";

export default function App() {
  return (
    <>
      <script $ServerOnly async src="https://www.googletagmanager.com/gtag/js?id=G-3NW4H4TEKE"></script>
      <script $ServerOnly>{`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-3NW4H4TEKE');
`}</script>
      {/* <script
        $ServerOnly
        async
        src="https://js.sentry-cdn.com/dc3f93189aec32a1b625535f83c81f8b.min.js"
        crossorigin="anonymous"
      ></script> */}
      <Router
        root={(props) => (
          <MetaProvider>
            <MixpanelProvider>
              <AuthProvider>
                <JulepProvider>
                  <PlaygroundProvider>
                    <Suspense>{props.children}</Suspense>
                  </PlaygroundProvider>
                </JulepProvider>
              </AuthProvider>
            </MixpanelProvider>
          </MetaProvider>
        )}
      >
        <FileRoutes />
      </Router>
    </>
  );
}
