import { mount, StartClient } from "@solidjs/start/client";
import * as Sentry from "@sentry/browser";
import { DEV } from "solid-js";
import { captureConsoleIntegration, reportingObserverIntegration, httpClientIntegration } from "@sentry/integrations";

// this will only initialize your Sentry client in production builds.
if (!DEV) {
  Sentry.init({
    dsn: "https://00e24d59672e67968f2b4bc6b37bc866@o4504699629993984.ingest.us.sentry.io/4506909586620416",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
      }),
      captureConsoleIntegration(),
      Sentry.breadcrumbsIntegration({
        dom: true,
        console: true,
        fetch: true,
        history: true,
        sentry: true,
        xhr: true,
      }),
      reportingObserverIntegration(),
      httpClientIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/api-alpha\.julep\.ai\/api/, /^https:\/\/dev\.julep\.ai\/api/],

    // Capture Replay for 10% of all sessions,
    // plus 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

mount(() => <StartClient />, document.getElementById("app"));
